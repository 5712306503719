import { render, staticRenderFns } from "./Enquiry.vue?vue&type=template&id=5fbddf2b&scoped=true&"
import script from "./Enquiry.vue?vue&type=script&lang=js&"
export * from "./Enquiry.vue?vue&type=script&lang=js&"
import style1 from "./Enquiry.vue?vue&type=style&index=1&id=5fbddf2b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbddf2b",
  null
  
)

export default component.exports